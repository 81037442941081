.craft-block {
  width: 70%;
  margin: 70px auto;
  font-family: 'Inter', sans-serif;
  margin-bottom: 15rem;

  .craftBanner {
    padding: 25;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
  
    .craftContent {
      z-index: 1;
      width: 60%;
      // background-color: ;
  
      h1 {
        font-size: 32px;
        font-weight: 400;
        text-align: left;
        margin-top: 0;
        margin-right: 10px;
        width: fit-content;
      }
  
      p {
        text-align: left;
        font-size: 24px;
        font-weight: 300;
        margin-right: 0;
      }
  
    }
  
    img {
      width: 30vw;
      height: auto;
      }
  }
  
  
  @media screen and (max-width: 1400px) {
    .craftBanner {
      width: 80%;
    }
  
  }
  
  @media screen and (max-width: 1200px) {
    .craftBanner {
      .craftContent {
        h1 {
          font-size: 52px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  
  @media screen and (max-width: 900px) {
    .craftBanner {
      flex-direction: column-reverse;
  
      .craftContent {
        h1 {
          font-size: 32px;
        }
        p {
          font-size: 20px;
        }
      }
  
      img {
        width: 50vw;
        height: auto;
        // margin-bottom: -60px;
      }
    }
  }
  
  @media screen and (max-width: 900px) {
    .craftBanner {
      .craftContent {
        h1 {
          font-size: 36px;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
        }
      }
  
      img {
        width: 50vw;
        height: auto;
      }
    }
  }
  
  @media screen and (max-width: 400px) {
    .craftBanner .craftContent {
      h1 {
        font-size: 28px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  
  
  // @-webkit-keyframes rotating {
  //   from {
  //     -webkit-transform: rotate(0deg);
  //     -o-transform: rotate(0deg);
  //     transform: rotate(0deg);
  //   }
  //   to {
  //     -webkit-transform: rotate(-360deg);
  //     -o-transform: rotate(-360deg);
  //     transform: rotate(-360deg);
  //   }
  // }
  
  // @keyframes rotating {
  //   from {
  //     -ms-transform: rotate(0deg);
  //     -moz-transform: rotate(0deg);
  //     -webkit-transform: rotate(0deg);
  //     -o-transform: rotate(0deg);
  //     transform: rotate(0deg);
  //   }
  //   to {
  //     -ms-transform: rotate(-360deg);
  //     -moz-transform: rotate(-360deg);
  //     -webkit-transform: rotate(-360deg);
  //     -o-transform: rotate(-360deg);
  //     transform: rotate(-360deg);
  //   }
  // }
}
.hero-block {
  width: 70%;
  margin: 70px auto;


  .heroSection {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;

    .heroContent {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        color: black;
        font-size: 48px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        text-align: left;

      }
  
      p {
        color: black;
        font-size: 24px;
        font-weight: 300;
        font-family: "Inter", sans-serif;
        text-align: left;
        margin: 0;
      }

      a {
        margin-top: 30px;
        background-color: #000000;
        width: fit-content;
        padding: 5px 25px 5px 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        transition: all ease-in-out 0.3s;
        
        p {
          color: #ffffff;
          font-size: 20px;
          font-weight: 400;
        }

        .arrowWrapper {
          overflow: hidden;
          position: relative;
          display: flex;
          align-items: center;
          margin-left: 10px;
          width: 30px;
          transition: all ease-in-out 0.3s;

          .rightArrow {
            position: absolute;
            left: -5px;
            transition: all ease-in-out 0.3s;
            fill: #ffffff;
          }
        }

        &:hover {
          text-decoration: none;
          padding-right: 18px;

          .arrowWrapper {
            width: 37px;
            .rightArrow {
              left: 0;
            }
          }
        }
      }


  
    }
  }

}
    
@media screen and (max-width: 1200px) {
  .heroSection {
    .heroContent {
      width: 80%;
      flex-direction: column;
      justify-content: center;
      
      h2 {
        font-size: 32px;
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .heroSection {
    .heroContent {
      width: 90%;
      flex-direction: column;
      justify-content: center;
      
      h2 {
        font-size: 24px;
        margin-top: 0;
      }
    }
  }
}

* {
  transition: all 0s;
}

.floor {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}

.footerWrapper {
  width: 100%;
  background-color: #f3f3f3;
  // padding: 6vw 0 50px 0;
}

.footer {
  color: #000000;
  // max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .imgWrapper {
    margin-left: 5vw;
    img {
      height: 120px;
      width: auto;
    }
  }

  .footerContent{
    display: grid;
    grid-template-rows: 4fr 1fr;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    padding: 30px 50px 40px 40px;

    .links {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;

      div .footerTitle {
          font-size: 20px;
          font-family: 'Inter', sans-serif;
          font-weight: 400;
          margin: 16px 0;
      }

      .siteLinks {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // margin-top: 30px;
        padding: 0;
        list-style: none;
        font-size: 16px;

        li {
          padding: 3px 0;

          a {
            font-family: 'Inter', sans-serif;
            font-weight: 300;
          }
        }
      }
    }

    .copyright {
      display: flex;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
      p {
        align-self: flex-end;
        font-size: 10px;
        font-weight: 200;
        margin: 0;
      }
    }
    
    .contactInfo {
      display: flex;
      flex-direction: column;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
  
      p.thin {
        font-weight: 200;
        margin-top: 30px;
      }

      a.locLink {
        margin-top: 10px;
      }

      a {
        display: flex;
        align-items: center;
        
        .svgWrapper {
          margin-right: 50px;

          svg {
            transform: translateY(2px);
          }
        }
      }
    }

    .legal {
      list-style: none;
      padding: 0;
      display: flex;
      align-content: center;
      align-items: flex-end;
      font-size: 10px;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;

      a {
        font-family: 'Inter', sans-serif;
        font-weight: 200;
      }

      .vertDivider {
        height: 10px;
        width: 1px;
        background-color: black;
        margin: 0 20px;
      }

    }

  }
  
}

a {
  color: #000000;
  text-decoration: none;

  &:hover {
    color: #EE161E;
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }


}

@media screen and (max-width: 1100px) {
  .footer {
    max-width: 80%;
  }
}
@media screen and (max-width: 900px) {
  .footer {
    max-width: 92%;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    max-width: 70%;

    .footerContent {
      flex-flow: column;
  
      .verticalLine {
        display: none;
      }

      .links {
        margin-top: 50px;

        .legal {
          margin-top: 50px;
          

          .vertDivider {
            height: 15px;
            width: 1px;
          }
        }
      }
    }

    .copyright {
      margin-top: 0;
      p {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .footer {
    max-width: 85%;
  }
}


.aboutPageWrapper {
  width: 100%;
  font-family: "Inter", sans-serif;
  
  .sectionHeroDescription{
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    h1 {
      font-weight: 500;
      font-family: "Inter", sans-serif;
      font-size: 48px;
      width: 70%;
    }
    p {
      font-weight: 300;
      font-size: 32px;
      width: 70%;
      font-family: "Inter", sans-serif;

      strong {
        font-weight: 500;
      }
    }
  }
  .section-highlights {
    width: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sectionHighlights {
      // margin-top: 300;
      // margin-bottom: 300;
      padding: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
  
      p {
        font-weight: 300;
        font-size: 24px;
        width: 70%;
  
      }
  
      .statsHighlightWrapper {
        width: 100%;
        padding: 50px;
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-basis: auto;
        align-items: center;
        justify-content: space-between;
        // position: relative;

        .statHighlight{ 
          padding: 20px;          
          .stat-highlight-title {
            font-weight: 400;
            font-size: 65px;
          }
          .stat-highlight-blurb {
            font-weight: 300;
            font-size: 24px;
          }
        }
      }
    }
  }

  .section-our-story {
    background-color: #F5F5F5;
    width: 100%;
    padding: 50px;

    .historyStory{
      margin-top: -150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px;
      h1 {
        text-align: left;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        font-size: 48px;
      }

      p {
        line-height: 1.5;
        line-break: auto;
        text-align: left;
        width: 50%;
        font-weight: 300;
        font-family: "Inter", sans-serif;
        font-size: 20px;

      }
    }

  } 

  .section-our-team {
    // background-color: #F5F5F5;
    width: 100%;
    padding: 50px;

    .ourTeam{
      margin-top: -150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px;
      h1 {
        text-align: left;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        font-size: 48px;
      }

      p {
        line-height: 1.5;
        line-break: auto;
        text-align: left;
        width: 50%;
        font-weight: 300;
        font-family: "Inter", sans-serif;
        font-size: 20px;

      }
    }

  } 


}

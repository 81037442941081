.header {
  /* Default styles */
  background-color: #FFFFFF;
  width: 100%;
  position: fixed;
  top: 0;
  color: #000000;
  font-size: 1.05rem;
  font-weight: 300;
  display: block;
  z-index: 100;
  justify-content: center;

  .headerContent{
    display: flex;
    flex-flow: row;
    max-width: 70%;
    margin: 0 auto;
    text-align: left;
    justify-content: space-between;
    z-index: 2;

    .imgWrapper {
      padding: 10px 0;
      img {
        height: 3rem;
        width: auto;

        &:hover {
          cursor: pointer;
        }
      }
    }
    
    .siteLinks {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: row;

      & > li {
        display: flex;
        align-items: flex-end;

        a {
          padding: 25px 15px;
          color: #000000c7;
          font-family: 'Inter', sans-serif;
          text-decoration: none;
          // text-transform: uppercase;

          span {
            padding-bottom: 2px;
            border-bottom: solid 2px transparent;
            transition: all 0.2s ease-in-out 0.1s;

            .downArrow {
              border: solid rgba(0, 0, 0, 0.8);
              border-width: 0 1.8px 1.8px 0;
              display: inline-block;
              padding: 3px;
              margin-bottom: 3px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transition: all 0.2s ease-in-out 0.1s;

            }
          }

          &:hover {
            color: #000000;

            span {
              border-bottom: solid 1.5px black;
            }
          }
        }

        &#productsHeader {
          position: relative;

          .dropdown {
            position: fixed;
            margin-top: -10px;
            width: 100vw;
            height: fit-content;
            left: 0;
            top: 71.5px;
            background-color: #ffffff;
            display: none;
            opacity: 0;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            border-radius: 10px;
            border: 0.5px solid rgba(0,0,0,0.2);
          }
  
          &:hover {
            a {
              color: #000000;

              span {
                border-bottom: solid 1.5px black;

                .downArrow {
                  color: #000000;
                  transform: rotate(225deg);
                  margin-bottom: 0px;
                }
              }
            }

            .dropdown {
              display: block;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .thinLine {
    height: 1px;
    background-color: #f3f3f3;
    width: 100%;
  }

}

.mobileHeader {
  display: none;
    
}

@media (max-width: 1200px) {
  .header {
    /* Styles for screens smaller than 1200px */
    .headerContent {
      max-width: 90%;
    }
  }
}

@media (max-width: 900px) {
  .header {
    /* Styles for screens smaller than 768px */
    display: none;
  }

  .mobileHeader {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    overflow: hidden;
    z-index: 10;

    .headerContent {
      width: 100%;
      height: 71.5px;
      padding: 10px 0;
      background-color: #FFFFFF;
      margin: 0 auto;
      
      &.isActive {
        height: 100vh;
        opacity: 1;
        background-color: #f3f3f3;
        z-index: 50;
      }
      
      .imgWrapper {
        position: absolute;
        left: 5%;
        top: 0;
        height: 71.5px;
        display: flex;
        align-items: center;

        img {
          height: 2.5rem;
          width: auto;
        }

        &.whoosh a {
          pointer-events: none;

          img {
          opacity: 0;
          visibility: none;
          }
        }
      }

      .burgerWrapper {
        position: fixed;
        top: 0;
        right: 5%;
        height: 71.5px;
        display: flex;
        align-items: center;
        
        .hamburgerMenu {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: center;
          height: 30px;
          width: 40px;
          padding: 2px;
          cursor: pointer;
          z-index: 100;
  
          .hamburgerLine {
            height: 3.5px;
            background-color: #4e4b5c;
            width: 100%;
            border-radius: 10px;
  
          }
  
          &.clicked {
            .hamburgerLine {
              &:nth-of-type(1){
                transform: translateY(11px) rotate(45deg);
              }
              &:nth-of-type(2){ 
                opacity: 0;
              }
              &:nth-of-type(3){
                transform: translateY(-11px) rotate(-45deg);
              }
            }
          }
          
        }
      }

    }

    .mobileSiteLinks {
      position: fixed;
      top: 10%;
      left: 10%;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        opacity: 0;
        visibility: hidden;
        text-align: left;
        font-size: 40px;
        font-weight: 300;
        padding-bottom: 15px;

        a {
          color: #4e4b5c;
          text-decoration: none;
        }

        &:nth-of-type(1) a {
          color: #EE161E;
        }
      }

      &.show {
        li {
          opacity: 1;
          visibility: visible;
          transition: all 0.5s ease-out 0.7s;
        }
      }
    }

    .thinLine {
      transition: opacity 0s, background-color 0.1s;
      height: 1px;
      background-color: #f3f3f3;
      opacity: 0.5;
      &.thickLine {
        opacity: 1;
      }
    }
  }
}

@font-face {
  font-family: 'Prompt';
  src:  url('/assets/fonts/Prompt/Prompt-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src:  url('/assets/fonts/Prompt/Prompt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src:  url('/assets/fonts/Prompt/Prompt-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Prompt';
  src:  url('/assets/fonts/Prompt/Prompt-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:  url('/assets/fonts/Inter/static/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:  url('/assets/fonts/Inter/static/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:  url('/assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:  url('/assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:  url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:  url('/assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src:  url('/assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


.App {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: rgb(243, 243, 243, 0.5);
}
/* // logo color #3671A4 */

*{
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;

  font-family: 'Inter', 'Prompt', sans-serif;
  font-weight: 400;

}

@media (prefers-reduced-motion: no-preference) {
}
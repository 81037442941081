.Home {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: rgb(243, 243, 243, 0.5);
}
/* // logo color #3671A4 */

*{
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  transition: 0.3s;

}

@media (prefers-reduced-motion: no-preference) {
}
.productsBlock {
  width: 70%;
  margin: 40px auto;
  display: flex;  
  flex-direction: column;
  justify-content: center;
  z-index: 1;

  .titleWrapper {
    width: fit-content;
    position: relative;
    margin-top: 50px;

    h1 {
      position: relative;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: 32px;
      margin: 0;
      text-align: left;
      width: fit-content;
      z-index: 4;
    }
  
  }

  .productHighlightDescriptions {
    width: fit-content;
    position: relative;
    // margin-top: 50px;

    p {
      position: relative;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 300;
      text-align: left;

    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    // grid-template-rows: ;
    grid-gap: 30px;
    row-gap: 50px;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 70px auto;
    list-style-type: none;
    z-index: 1;

    li {
      border: .5px solid #4e4b5c;
      border-radius: 25px;
      overflow: hidden;
      transition: 0.3s;
      width: 100%;
      background-color: white;
      height: fit-content;

      &:hover {
        box-shadow: 0 0 10px #999999;

        a .tempBox img {
          transform: scale(1.1);
        }
      }

      a {
        text-decoration: none;
        color: #000000;

        .tempBox {
          aspect-ratio: 1/1;
          background-color: rgba(227, 183, 5, 0.2);
          z-index: -1;
          overflow: hidden;

          img {
            z-index: -2;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }

        p {
          text-align: left;
          margin-left: 16px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          font-size: 20px;
          background-color: #ffffff;
          z-index: 5;
        }
      }      
    }
  }

}

@media screen and (max-width: 1200px) {
  .productsBlock {
    width: 90%;
  }
}
@media screen and (max-width: 1050px) {
  .productsBlock {
    width: 90%;
  }
}
@media screen and (max-width: 900px) {
  .productsBlock {

    .titleWrapper {
      width: 80%;
      margin: 0 auto;
    }

    ul {
      justify-content: space-evenly;
    }
  }
}

@media screen and (max-width: 600px) {
  .productsBlock {
    width: 80%;

    .titleWrapper {
      width: 400px;
      margin: 0 auto;
    }

    ul {
      grid-template-columns: repeat(auto-fill, 400px);
    }
  }
}

@media screen and (max-width: 500px) {
  .productsBlock {

    .titleWrapper {
      width: 300px;
      margin: 0 auto;
    }

    ul {
      grid-template-columns: repeat(auto-fill, 300px);
    }
  }
}
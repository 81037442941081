/* Component 60 */
.contactsForm{
  width: 70%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // width: 100%;
  margin: 150px auto;

  .titleWrapper{
      width: fit-content;
      position: relative;
      justify-content: center;
      margin: 0px auto;
  
      // /* CONTACT */
      .titleContact{
          z-index: 3;
          position: relative;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 72px;
          /* identical to box height */
          text-align: center;
          margin: 0px;
          
          color: #000000;
      
      }
      // /* highlight line */
      .highlightLine{
        bottom: .5%;
        left: 1%;
        width: 50%;
        height: 6px;
  
        position: absolute;
        background: #FF5656;
      }

      // /* Rectangle 71 */

      // position: absolute;
      // left: 27.1%;
      // right: 26.8%;
      // top: 65.98%;
      // bottom: 18.56%;

      // .thinLine {
      //     z-index: 3;
      //     width: 99%;
      //     height: 8px;
      //     background-color: #fcba04;
      //     position: absolute;
      //     bottom: 22%;
      //     left: 0;
      //   }
  .contactsFormWrapper{
      width: 70%;
      background-color: white;
      display: flex;
      flex-wrap: wrap;

      // justify-content: center;
  }
      
  }

  .contactsFormWrapper{
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .sideContent{
      background-color: aquamarine;
      margin: 5%;
      opacity: .2;
      width: 50%;

    }

    .contactsForm{
      width: 50%;
      margin: 5%;
      box-shadow: 0px 0px 20px 1px rgba(0,0,0,.1);


      form {
        

        height: 500px;
        padding: 20px;
        width: 95%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        margin: 20px auto;

        * {
          width: 100%;
          margin: 0 auto;
        }

        input {
          padding:10px 8px;
          // margin: 5px;
        }
      }
    }
  }


}

@media screen and (max-width: 1200px) {
    
}
  
  @media screen and (max-width: 768px) {
}

.get-quote-block {
  .getQuoteBanner {
    position: relative;
    display: flex;
    height: 570px;
    overflow-x: hidden;
    background-image: url('../../assets/images/getQuoteGradient.png');
    background-size: cover;
    
    .asterisk {
      position: absolute;
      top: 0;
      width: auto;
      height: 100%;
      z-index: 2;
      transform: translate(-46.4%, 0);

    }

    .getQuoteContent {
      margin: 0 15vw 0 50vw;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        color: #ffffff;
        font-size: 48px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        margin: 0;

      }
  
      p {
        color: #ffffff;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        font-weight: 400;
        margin-top: 20px;
        margin-bottom: 40px;
      }

      a {
        color: #ffffff;
        background-color: #000000;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 24px;
        width: fit-content;
        margin: 0 auto;
        padding: 15px 55px;
        border-radius: 10px;
        box-shadow: 0px 5px 8px 0px rgba(0,0,0,.15);
        transition: all .3s ease-in-out;

        &:hover {
          text-decoration: none;
          box-shadow: 0px 7px 8px 0px rgba(0,0,0,.25);
          transform: translateY(-2px);
        }

      }
    }
  }

}
    
@media screen and (max-width: 1200px) {
  .getQuoteBanner {
    .getQuoteContent {
      width: 80%;
      flex-direction: column;
      justify-content: center;
      
      h2 {
        font-size: 32px;
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .getQuoteBanner {
    .getQuoteContent {
      width: 90%;
      flex-direction: column;
      justify-content: center;
      
      h2 {
        font-size: 24px;
        margin-top: 0;
      }
    }
  }
}

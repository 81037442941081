

.whyUsBanner {
  margin: 0px auto;
  margin-bottom: 100px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  

  h1 {
    font-family: 'Inter', sans-serif;
    font-size: 64px;
    font-weight: 300;
    margin-top: 0;
    width: fit-content;
  }

  .whyUsContent {

    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .halfBlock {

      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 400px;
      height: 400px;
      
      .quarterBlock {
  
        h2 {
          font-family: 'Inter', sans-serif;
          font-size: 32px;
          font-weight: 400;
          // letter-spacing: 5px;
          margin-bottom: 0;
          margin-top: 16px;
          text-align: center;
        }
        
        p {
          font-family: 'Inter', sans-serif;
          text-align: center;
          font-size: 16px;
        }
  
      }
    }

    img {
      width: 250px;
      height: 400px;
      overflow: hidden;
    }

    #bookletImg {
      background-image: url('../../assets/images/hero3.jpg');
      width: 250px;
      height: 400px;
      background-size: cover;
      overflow: hidden;
    }

  }

}


@media screen and (max-width: 1400px) {
  .craftBanner {
    width: 80%;
  }

}

@media screen and (max-width: 1200px) {
  .craftBanner {
    .craftContent {
      h1 {
        font-size: 52px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .craftBanner {
    flex-direction: column-reverse;

    .craftContent {
      h1 {
        font-size: 64px;
      }
      p {
        font-size: 20px;
      }
    }

    img {
      width: 50vw;
      height: auto;
      // margin-bottom: -60px;
    }
  }
}

@media screen and (max-width: 900px) {
  .craftBanner {
    .craftContent {
      h1 {
        font-size: 36px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
      }
    }

    img {
      width: 50vw;
      height: auto;
    }
  }
}

@media screen and (max-width: 400px) {
  .craftBanner .craftContent {
    h1 {
      font-size: 28px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }
}


@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
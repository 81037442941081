.buildingPageWrapper {
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;

    .mainBody{
        width: 70%;
        display: flex;
        flex-direction: column;
        
    }
}
.product-block {
  margin: 150px auto 50px auto;
  display: grid;
  grid-template-columns: [first] 50% [second] auto [third] 45% [fourth];
  grid-template-rows: [row1] 50px [row2] auto [row3];
  width: 90%;
  // background-color: #f5f5f5;
}

.breadcrumb {
  text-align: left;
  grid-column: first / second;
  grid-row: row1 / row2;
}

.product-image {
  grid-column: first / second;
  grid-row: row2 / row3;
  // border: 1px solid #000;
  img {
    width: 100%;
    height: auto;
    border: 1px;
    border-radius: 10px;
    background-color: #fff;
  }
}

.product-details {
  grid-column: third / fourth;
  grid-row: row2 / row3;
  text-align: left;
  display: flex;
  flex-direction: column;

  .title {
    line-height: normal;
    font-size: 2rem;
  }

  .product-description {
    font-size: 1.2rem;
  }

  .product-price {
    font-size: 1.5rem;
    color: #b12704;
    margin-top: 0;
  }

  .add-to-cart {
    background-color: #ff9900;
    border: none;
    color: white;
    padding: 10px 20px;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
  }
  .product-options {
    
    label {
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
    select {
      display: block;
      width: fit-content;
      padding: 8px;
      margin-bottom: 15px;
      font-size: 1rem;
    }
  }
}
// .add-to-cart:hover {
//   background-color: #e68a00;
// }
